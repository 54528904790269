import store from '../store';

const getMenu = function () {
    const user = store.getters.currentUser;

    const menu = [
        {
            icon: 'ni ni-user-run',
            text: 'Home',
            link: '/user/shops/' + user.defaultshop + '/products/'
        },
        {
            icon: 'ni ni-user-run',
            text: 'cart',
            link: '/user/cart/' + user.id + '/'
        },
        {
            text: user.name,
            link: '#',
            items: [
                {
                    text: 'Profile',
                    link: '/user/details/' + user.id + '/'
                },
                {
                    text: 'Change Password',
                    link: '/app/master-data/'
                },
                {
                    text: 'Logout',
                    link: '/app/master-data/'
                }
            ]
        }
    ];

    const adminMenu = [
    ];

    // Do something similar for any number of roles
    if (user && user.admin === true) {
        return [
            ...menu,
            ...adminMenu
        ];
    }
    return [
        ...menu
    ];
};
export default getMenu;
