<template>
    <div class="page-wrapper">
        <div class="header">
            <img src="../assets//img/log.jpg" @click="Home"/>
            <a @click="Cart"><i class="fa fa-shopping-cart"></i></a>
        </div>
        <div class="page-content">
            <transition name="fade" mode="out-in">
                <router-view>

                </router-view>
            </transition>
        </div>
        <div class="bg-black footer">
            Powered by Xeoscript Technologies
        </div>
    </div>
</template>

<script>
import getMenu from '../data/menu';
import { mapGetters } from 'vuex';

export default {
    components: {},
    data () {
        return {
            menu: getMenu()
        };
    },
    computed: {
        ...mapGetters(['currentUser']),
        isAdmin () {
            const currentUser = this.currentUser;
            return currentUser && currentUser.role === 'Shop Admin';
        }
    },
    methods: {
        Cart () {
            this.$router.push({ path: '/cart/' });
        },
        Orders () {
            this.$router.push({ path: '/admin/orders/' });
        },
        addProducts () {
            this.$router.push({ path: '/admin/product-add/' });
        },
        Home () {
            if (this.isAdmin) {
                this.$router.push({ path: '/' });
            } else {
                this.$router.push({ path: '/' });
            }
        }
    },
    metaInfo: {
        titleTemplate: '%s - Pentagon Cafe',
        htmlAttrs: {
            lang: 'en',
            amp: true
        }
    },
    name: 'MainLayout'
};
</script>

<style>

.page-wrapper .footer {
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bg-black {
    background-color: black !important;
    color: white !important;
}
</style>
